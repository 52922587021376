import { Footer } from '../common/Footer/Footer';
import { Header } from '../common/Header/Header';
import SmallFooter from '../common/Footer/SmallFooter';
import { BackgroundImage } from '../../assets/Container/BackgroundImage';
import mainBg from '../../assets/images/notRainbow.png';
import SideBar from '../SideBar/SideBar';

export const MainLayout = ({ children }) => (
  <>
    <Header />
    <div className="mx-auto ">{children}</div>
    <SideBar />

    <Footer />
  </>
);
