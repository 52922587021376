import { useState } from 'react';
import ReactPlayer from 'react-player';
import SlideshowGallery from '../VideoSlideshow/SlideShowGallery';

const mainBackground = 'https://assets.thegratitudebear.com/BACKGROUND.png';
const Web3Tv =
  'https://www.youtube.com/watch?v=n99xIF_m2q8&list=PLbarA7pe49igrs33ej_vf7h9kWzCqxS7f';
const Web3Tv2 =
  'https://www.youtube.com/watch?v=THp4J6zyrgM&list=PLbarA7pe49igrs33ej_vf7h9kWzCqxS7f';
const link3 = 'https://www.youtube.com/watch?v=Mx2Rr4jZFBs';
function MediaGallery() {
  const bearPair = 'https://assets.thegratitudebear.com/BearPair.png';
  return (
    <section
      className="flex flex-col py-12 px-4 bg-off-white gap-40 "
      style={{
        backgroundImage: `url(${mainBackground})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <div className="flex flex-col max-w-6xl mx-auto gap-10">
        <div className="flex flex-col mb-8 items-center gap-5">
          <h2 className="text-5xl font-bold text-gratitude-purple font-baloo2  text-center md:text-left">
            The Gratitude Bear at Future Blockchain Summit!
          </h2>
          <div className="flex flex-col md:flex-row lg:flex-row justify-between gap-5 items-center">
            <div className="flex flex-col gap-10 items-center ">
              <p className="text-gratitude-purple  mt-4 font-baloo2 max-w-md  text-2xl font-bold text-center">
                Learn about the Gratitude Bear with our CEO Aaron Spain for
                Web3Tv
              </p>
              <div className="flex flex-col md:flex-row lg:flex-row self-center gap-5">
                <ReactPlayer
                  url={Web3Tv}
                  width="340px" // Set a fixed width
                  height="180px" // Set a fixed height
                  playing={false} // Set to true to auto-play
                  controls // Set to true to show player controls
                  light // Set to true to show preview image (pass a URL to a custom preview image if you want)
                  // Other props
                />
                <ReactPlayer
                  url={Web3Tv2}
                  width="340px" // Set a fixed width
                  height="180px" // Set a fixed height
                  playing={false} // Set to true to auto-play
                  controls // Set to true to show player controls
                  light // Set to true to show preview image (pass a URL to a custom preview image if you want)
                  // Other props
                />
                <ReactPlayer
                  url={link3}
                  width="340px" // Set a fixed width
                  height="180px" // Set a fixed height
                  playing={false} // Set to true to auto-play
                  controls // Set to true to show player controls
                  light // Set to true to show preview image (pass a URL to a custom preview image if you want)
                  // Other props
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col text-left mb-8 items-center ">
          <h2 className="text-6xl font-bold text-gratitude-purple font-baloo2  text-center ">
            How Does The Gratitude Bear work?
          </h2>
          <p className="text-2xl text-gratitude-purple font-bold font-marope text-center">
            Watch our first series of cartoons!
          </p>
          <SlideshowGallery />
        </div>
      </div>
    </section>
  );
}

export default MediaGallery;
