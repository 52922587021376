import {
  AiFillFacebook,
  AiFillInstagram,
  AiFillLinkedin,
  AiFillYoutube,
  AiFillTwitterCircle,
} from 'react-icons/ai';

import { BsTelegram } from 'react-icons/bs';

export const socialMedia = [
  {
    id: 'facebook',
    icon: AiFillFacebook,
    url: 'https://www.facebook.com/thegratitudebearofficial',
    colorScheme: 'text-facebook-blue',
  },
  {
    id: 'instagram',
    icon: AiFillInstagram,
    url: 'https://www.instagram.com/thegratitudebearofficial/',
    colorScheme: 'text-black',
  },
  {
    id: 'youtube',
    icon: AiFillYoutube,
    url: 'https://www.youtube.com/channel/UCZz4fT70Tr1zdJIypwKY6PA',
    colorScheme: 'text-youtube-red',
  },
  {
    id: 'linkedIn',
    icon: AiFillLinkedin,
    url: 'https://www.linkedin.com/company/thegratitudebearofficial/',
    colorScheme: 'text-linked-blue',
  },
  {
    id: 'telegram',
    icon: BsTelegram,
    url: 'https://t.me/TheGratitudeBearPortal',
    colorScheme: 'text-telegram-blue',
  },
  {
    id: 'twitter',
    icon: AiFillTwitterCircle,
    url: 'https://twitter.com/GratitudeBearHQ',
    colorScheme: 'text-black',
  },
];
