import { useState } from 'react';
import { NavLinks } from './NavLinks';

const mainLogo = 'https://assets.thegratitudebear.com/NewLogo.png';
const BurguerIcon = ({ onClick = () => {} }) => (
  <button
    type="button"
    className="flex flex-col gap-1 bg-black p-2 rounded-lg lg:hidden hover:bg-polygon-gray duration-300"
    onClick={onClick}
  >
    <div className="w-8 h-1 bg-gratitude-purple rounded-lg" />
    <div className="w-8 h-1 bg-gratitude-purple rounded-lg" />
    <div className="w-8 h-1 bg-gratitude-purple rounded-lg" />
  </button>
);

export const MobileNavbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleIsOpen = () => setIsOpen(!isOpen);

  return (
    <>
      <BurguerIcon onClick={toggleIsOpen} />
      {isOpen && (
        <div className="fixed h-screen w-screen top-0 left-0 flex lg:hidden z-50">
          <div className="relative h-full bg-off-white w-4/5 py-16">
            <button
              type="button"
              className="rounded-full py-1 px-3 absolute top-2.5 right-2.5 text-3xl font-extrabold cursor-pointer text-polygon-black hover:text-white duration-300"
              onClick={toggleIsOpen}
            >
              X
            </button>
            <img
              src={mainLogo}
              alt="Logo"
              className="mx-auto w-40 h-auto object-contain"
            />
            <div className="flex flex-col mt-5 items-center gap-3 ">
              <NavLinks />
            </div>
          </div>
          <div className="flex-1 bg-gray-500 opacity-60" />
        </div>
      )}
    </>
  );
};
