import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import React, { lazy, Suspense } from 'react';
import { ROUTES } from '../constants/routes';
import Loading from '../components/Loading/Loading';
import MediaPage from '../pages/MediaPage';

const HomePage = lazy(() => import('../pages/HomePage'));
const AboutUsPage = lazy(() => import('../pages/AboutUsPage'));
const NotFoundPage = lazy(() => import('../pages/NotFoundPage'));
const DisclaimerPage = lazy(() => import('../pages/DisclaimerPage'));
const ComingSoon = lazy(() => import('../pages/ComingSoon'));
const RoadMap = lazy(() => import('../pages/RoadMap'));
const Media = lazy(() => import('../pages/MediaPage'));
const router = createBrowserRouter([
  {
    path: ROUTES.HOME,
    element: <HomePage />,
    errorElement: <NotFoundPage />,
  },
  {
    path: ROUTES.ABOUT,
    element: <AboutUsPage />,
  },

  {
    path: ROUTES.DISCLAIMER,
    element: <DisclaimerPage />,
  },
  {
    path: ROUTES.SHOP,
    element: <ComingSoon />,
  },
  {
    path: ROUTES.ROADMAP,
    element: <RoadMap />,
  },
  {
    path: ROUTES.MEDIA,
    element: <Media />,
  },
]);

export const AppRouter = () => (
  <Suspense
    fallback={
      <div>
        <Loading />
      </div>
    }
  >
    <RouterProvider router={router} />
  </Suspense>
);
