import { socialMedia } from '../../data/socialMedia';
import { Image } from '../Atoms/Image';

function SideBar() {
  return (
    <div className="fixed z-30 top-96 lg:top-1/2 right-0 bg-gray-200 rounded-l-xl py-3 pl-3 pr-5 lg:-translate-y-1/2 bg-opacity-70  ">
      {socialMedia.map(({ id, icon: Icon, url, colorScheme }) => (
        <a href={url} key={id} target="_blank" rel="noopener noreferrer">
          <Icon
            className={`w-6 h-6 md:w-8 md:h-8 lg:w-12 lg:h-12 ${colorScheme} hover:text-gray-300 duration-300 mb-2`}
          />
        </a>
      ))}
    </div>
  );
}

export default SideBar;
