export const ROUTES = {
  HOME: '/',
  SERVICES: '/FAQ',
  PACKAGES: '/packages',
  DISCLAIMER: '/disclaimer',
  ABOUT: '/us',
  ROADMAP: '/roadmap',
  SHOP: '/shop',
  MEDIA: '/media',
};

export const ROUTE_NAMES = {
  HOME: 'Home',
  ABOUT: 'About Us',
  SERVICES: 'FAQ',
  DISCLAIMER: 'Legal Disclaimer',
  PACKAGES: 'Packages',
  ROADMAP: 'Roadmap',
  METAVERSE: 'Metaverse',
  SHOP: 'Shop',
  MEDIA: 'Media',
};
