import { Link, useLocation } from 'react-router-dom';

import { ROUTES } from '../../../constants/routes';
import { DesktopNavbar } from '../Navbars/DesktopNavbar';
import { MobileNavbar } from '../Navbars/MobileNavbar';

const headerLogo = 'https://assets.thegratitudebear.com/BearLogo.png';

export const Header = () => {
  const location = useLocation();

  // Function to determine header class based on the current route
  const getHeaderClass = (pathname) => {
    switch (pathname) {
      case ROUTES.HOME:
        return 'bg-sky-blue'; // Replace with the actual color class for the home route
      case ROUTES.ABOUT:
        return 'bg-soft-gray'; // Replace with the actual color class for the about route
      case ROUTES.TOKENOMICS:
        return 'bg-tokenomics-color'; // Replace with the actual color class for the tokenomics route
      case ROUTES.ROADMAP:
        return 'bg-sky-blue'; // Replace with the actual color class for the roadmap route
      case ROUTES.MEDIA:
        return 'bg-sky-blue'; // Replace with the actual color class for the roadmap route
      default:
        return 'bg-default-color'; // Default color class when no other route matches
    }
  };

  const headerClass = getHeaderClass(location.pathname);

  return (
    <header
      className={`${headerClass} w-full mx-auto py-2 px-2 md:px flex md:flex-col lg:flex-row gap-5 items-center justify-between`}
    >
      <div className=" ml-40   invisible md:visible lg:visible">
        <Link to={ROUTES.HOME}>
          <img
            src={headerLogo}
            alt="Logo"
            className=" w-[110px] h-auto object-contain hover:scale-105 duration-300"
          />
        </Link>
      </div>
      <DesktopNavbar />
      <MobileNavbar />
    </header>
  );
};
