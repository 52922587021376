import React from 'react';
import { NavLink } from 'react-router-dom';
import { NAVBAR_LINKS } from '../../../constants/navbar';

export const NavLinks = () =>
  NAVBAR_LINKS.map(({ to, name }) => (
    <NavLink
      key={to}
      to={to}
      className={({ isActive }) =>
        `${
          isActive
            ? 'text-gratitude-purple border border-2 border-gratitude-purple'
            : 'text-black'
        } uppercase text-center px-5 py-2 rounded-full font-baloo2 font-bold text-lg duration-300  hover:text-gratitude-purple  hover:border-2 hover:border-gratitude-purple rounded-full   duration-300 `
      }
    >
      {name}
    </NavLink>
  ));
