import { ROUTES, ROUTE_NAMES } from './routes';

export const NAVBAR_LINKS = [
  {
    to: ROUTES.HOME,
    name: ROUTE_NAMES.HOME,
  },

  {
    to: ROUTES.ABOUT,
    name: ROUTE_NAMES.ABOUT,
  },
  {
    to: ROUTES.ROADMAP,
    name: ROUTE_NAMES.ROADMAP,
  },
  {
    to: ROUTES.MEDIA,
    name: ROUTE_NAMES.MEDIA,
  },
];
