/* eslint-disable react/jsx-curly-brace-presence */
import { SiGitbook } from 'react-icons/si';
import { GiBearFace } from 'react-icons/gi';
import { BiPolygon } from 'react-icons/bi';
import { NavLink } from 'react-router-dom';
import { FaTrademark, FaRegCopyright } from 'react-icons/fa';
import { IoMdCheckmarkCircleOutline } from 'react-icons/io';

export const Footer = () => (
  <div className="bg-blue-gradient-custom p-10 flex flex-col gap-10 md:flex-row md:justify-between items-center font-baloo2">
    <img
      src="https://assets.thegratitudebear.com/BlueSittingBear.png"
      alt="BlueSittingBear"
    />
    <ul className="flex flex-col space-y-2 self-start md:self-center">
      <li className="text-off-white text-left">
        <div className="flex items-center">
          <NavLink
            to="/us"
            className="flex flex-row gap-2 items-center hover:text-black duration-300"
          >
            About Us
            <GiBearFace />
          </NavLink>
        </div>
      </li>
      <li className="text-off-white text-left">
        <div className="flex items-center">
          <NavLink
            to="/roadmap"
            className="flex flex-row gap-2 items-center hover:text-black duration-300"
          >
            Roadmap
            <GiBearFace />
          </NavLink>
        </div>
      </li>
      <li className="text-off-white text-left">
        <div className="flex items-center">
          <NavLink
            to="/disclaimer"
            className="flex flex-row gap-2 items-center hover:text-black duration-300"
          >
            Terms Of Use
            <GiBearFace />
          </NavLink>
        </div>
      </li>
      <li className="text-off-white text-left">
        <div className="flex items-center">
          <a
            rel="noreferrer"
            target="_blank"
            href="https://metaverse.thegratitudebear.com/"
            className="flex flex-row gap-2 items-center hover:text-black duration-300"
          >
            Ecosystem
            <BiPolygon />
          </a>
        </div>
      </li>
      <li className="text-off-white text-left">
        <div className="flex items-center ">
          <a
            rel="noreferrer"
            target="_blank"
            href="https://docs.thegratitudebear.com/"
            className="flex flex-row gap-2 items-center hover:text-black duration-300"
          >
            Gitbook
            <SiGitbook />
          </a>
        </div>
      </li>
    </ul>

    <div className="text-off-white flex flex-col  gap-4 text-xs">
      <div className="flex flex-row items-center gap-5">
        <FaTrademark className="h-8 w-8" /> {'"Gratitude Bear"'} & the bears
        characters trademarks currently under application approval
      </div>
      <div className="flex flex-row items-center gap-5">
        <FaRegCopyright className="h-8 w-8" /> All storylines and relevant copy
        is protected under international copyright laws
      </div>
      <div className="flex flex-row items-center gap-5">
        <IoMdCheckmarkCircleOutline className="h-8 w-8" /> Innovation patent
        granted
      </div>
      <div className="flex flex-row items-center gap-5">
        <IoMdCheckmarkCircleOutline className="h-8 w-8" /> Design registration
        granted
      </div>
    </div>
  </div>
);
