/* eslint-disable react/button-has-type */
/* eslint-disable react/no-array-index-key */
import ReactPlayer from 'react-player';
import { useRef } from 'react';

const videoUrls = [
  'https://www.youtube.com/watch?v=Ykbxkn1MRs8',
  'https://www.youtube.com/watch?v=0IqqRrxSIpk',
  'https://www.youtube.com/watch?v=XYFbSYFU4S8', // Add your video URLs here
  'https://www.youtube.com/watch?v=GLfOoCk7ckw',
  'https://www.youtube.com/watch?v=CUWTusJdRZM',
  'https://www.youtube.com/watch?v=Gr7dIIkYo5s&',
  'https://www.youtube.com/watch?v=NQz9Q6VPN_o',
  // ... more video URLs
];

const SlideshowGallery = () => {
  const galleryRef = useRef(null);

  const isHorizontalLayout = () =>
    // This can be adjusted based on your breakpoint for md size
    window.innerWidth >= 768;
  const scroll = (direction) => {
    if (galleryRef.current) {
      if (isHorizontalLayout()) {
        // Horizontal scrolling
        const { scrollLeft, clientWidth } = galleryRef.current;
        const scrollTo =
          direction === 'left'
            ? scrollLeft - clientWidth
            : scrollLeft + clientWidth;
        galleryRef.current.scrollTo({ left: scrollTo, behavior: 'smooth' });
      } else {
        // Vertical scrolling
        const { scrollTop, clientHeight } = galleryRef.current;
        const scrollTo =
          direction === 'up'
            ? scrollTop - clientHeight
            : scrollTop + clientHeight;
        galleryRef.current.scrollTo({ top: scrollTo, behavior: 'smooth' });
      }
    }
  };

  return (
    <>
      <div
        ref={galleryRef}
        className="flex md:flex-row flex-col overflow-hidden gap-4 p-4"
      >
        {videoUrls.map((url, index) => (
          <div key={index} className="flex-shrink-0 w-full md:w-auto">
            <ReactPlayer url={url} controls width="100%" height="100%" />
          </div>
        ))}
      </div>
      <div className="flex justify-center gap-4 mt-2">
        {isHorizontalLayout() ? (
          <>
            <button onClick={() => scroll('left')}>Previous</button>
            <button onClick={() => scroll('right')}>Next</button>
          </>
        ) : (
          <>
            <button onClick={() => scroll('up')}>
              {' '}
              <button onClick={() => scroll('left')}>Previous</button>
            </button>
            <button onClick={() => scroll('down')}>Next</button>
          </>
        )}
      </div>
    </>
  );
};

export default SlideshowGallery;
